.prompt {
  margin-top: 10%;
  /* height: 50px;  */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prompt p {
  font-size: 1.5rem;
  font-weight: bold;
}

.text {
  font: Ariel;
  font-size: 30px;
  text-align: center;
}
.logo {
  width: 150px;
  border-radius: 8px;
  margin-top: 15px;
}
/* .b {
  margin-top: 10px;
  background-color: #ede7e3;
  border-radius: 7px;
  border-width: 3px;
  border-color: black;
  width: 100px;
} */
.generator {
  border: black;
  width: 100px;
  margin-top: 8%;
  margin-bottom: 20%;
  margin-left: 45%;
}

body {
  background-image: linear-gradient(to bottom right, #72a48f, #6db094, #72a48f);

  background-repeat: repeat;
  /* overflow-y: hidden; */
}

/* .sign-up {
  color: white;
} */
.line-4 hr {
  height: 3px;
  border: 0;
  background: white;
}
.g {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gText {
  width: 50%;
  align-items: center;
  font-size: 20px;
  white-space: pre-wrap;
}

.g button {
  background-color: #ff6b6b;
}
