.nav {
  background-color: #f8f9fa;
  padding: 10px 0;
  width: 100%;
}

.logo {
  width: 150px;
  height: auto;
}

.sign-up {
  color: #333;
  margin-right: 10px;
  text-decoration: none;
  font-size: larger;
  font-weight: bold;
}

.sign-up:hover {
  color: #ff6b6b;
}
