.auth {
  width: 100%;
}

h1 {
  font-size: 3rem;
  margin-bottom: 50px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f8f9fa;
}

tr:hover {
  background-color: #f0f0f0;
}

.container {
  margin-bottom: 20px;
  height: 100%;
}
