input {
  height: 25px;
  margin-top: 16px;
  background-color: FFFFFA;
  border-color: black;
  border-radius: 5px;
  border-width: 1px;
  font-size: 18px;
  margin-left: 15px;
}

.logo {
  width: 150px;
  border-radius: 8px;
  margin-top: 15px;
}
.text {
  display: flex;
  flex-direction: column;

  margin-top: 7%;
}
.box {
  display: flex;
  justify-content: center;
}
form {
  background-color: #edf6f9;
  opacity: 0.8;
  padding: 50px;
  margin-left: 20%;
  margin-right: 20%;
}
.auth {
  background-image: linear-gradient(to bottom right, #72a48f, #6db094, #72a48f);
}
.b {
  width: 200px;
  background-color: #ff6b6b;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
